import React from 'react';

function NotFound() {
  return (
    <h1>
      <center>Oops! This page doesn&apos;t exist!</center>
    </h1>
  );
}

export default NotFound;
